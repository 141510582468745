import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './main.css';
import ResetPassword from './pages/reset-password/ResetPassword';
require('dotenv').config();

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="app-background">
          <Route path="/reset-password" component={ResetPassword} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
