import React, { useEffect, useState, useRef, useCallback } from "react";

import BounceLoader from "react-spinners/ClipLoader";
import title from "../../assets/title.png";
import logo from "../../assets/logo.png";
import { api } from "../../utility/api";

function ResetPassword(location) {
  const [loading, setLoading] = useState(false);
  const [infoMessage, setInfoMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const submitButton = useRef(null);

  const checkCodeIsValid = useCallback(
    async (newPassword) => {
      setLoading(true);
      const searchQuery = location.location.search;
      const resetCode = searchQuery.replace("?c=", "");

      let codeData = new FormData();
      codeData.append("code", resetCode);

      if (newPassword) {
        codeData.append("new_password", newPassword);
      }

      const { data } = await api.post("/reset-password", codeData, {});
      setLoading(false);

      return data;
    },
    [location.location.search]
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setErrorMessage(null);

      const newPassword = event.target.new_password.value;
      const confirmPassword = event.target.confirm_password.value;

      if (newPassword && newPassword === confirmPassword) {
        const data = await checkCodeIsValid(newPassword);

        if (data?.success) {
          setInfoMessage("Your password has been changed successfully!");
        } else {
          setInfoMessage(data?.comment);
        }
      } else {
        setErrorMessage(
          "The new password and confirm password does not match!"
        );
      }
    },
    [checkCodeIsValid]
  );

  const resetPasswordClicked = useCallback(() => {
    if (submitButton) {
      submitButton.current?.click();
    }
  }, []);

  const init = useCallback(async () => {
    const data = await checkCodeIsValid();

    if (!data?.success) {
      setInfoMessage(data?.comment);
    }
  }, [checkCodeIsValid]);

  useEffect(() => {}, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className="center-items reset-password-background">
      <div className="header-container">
        <img className="logo-icon" src={title} alt="logo" />
      </div>

      {loading === false ? (
        <div className="center-items full-width">
          {infoMessage == null ? (
            <div className="center-items full-width">
              <img src={logo} className="padlock-icon" alt="logo" />

              <div className="reset-password-title">Reset Password</div>
              <div
                className="reset-password-description"
                style={{ color: errorMessage ? "red" : null }}
              >
                {errorMessage ? errorMessage : "Enter new password below"}
              </div>

              <form
                className="reset-password-form center-items"
                onSubmit={handleSubmit}
              >
                <div className="text-input-container">
                  <input
                    type="password"
                    className="text-input"
                    maxLength="50"
                    name="new_password"
                    placeholder="New Password"
                    required
                  />
                </div>
                <div className="text-input-container">
                  <input
                    type="password"
                    className="text-input"
                    maxLength="50"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    required
                  />
                </div>
                <button
                  ref={submitButton}
                  className="hidden-submit-button"
                  type="submit"
                />

                <div
                  className="reset-password-button"
                  onClick={resetPasswordClicked}
                >
                  Reset Password
                </div>
              </form>
            </div>
          ) : (
            <div className="message-container">{infoMessage}</div>
          )}
        </div>
      ) : (
        <div className="loading-container">
          <BounceLoader
            // css={css}
            size={50}
            color={"#259BE4"}
            loading={true}
          />
        </div>
      )}
    </div>
  );
}

export default ResetPassword;
